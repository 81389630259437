<template>
  <router-link
   :to="{ name: 'post', params: { postSlug: datas.slug } }" 
    v-if="stype === 'small'"
    class="feature-post d-block"
  >
    <div class="feature-post-thumb">
      <img v-if="datas.imagePath" :src="getImageUrl(datas.imagePath)" alt=""/>
      <img v-else src="@/assets/images/7br-placeholder.png" alt="" />
    </div>
    <div class="feature-post-content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-date">
          <span>{{ datas.created_At }}</span>
        </div>
      </div>
      <h4 class="title">
        <a href="#">{{ datas.title }}</a>
      </h4>
    </div>
  </router-link>
  <router-link
  :to="{ name: 'post', params: { postSlug: datas.slug } }" 
    v-else-if="stype === 'big'"
    class="single-play-post-item d-block"
  >
  <img v-if="datas.imagePath" :src="getImageUrl(datas.imagePath)" alt=""/>
  <img v-else src="@/assets/images/7br-placeholder.png" alt="" />
    <div class="single-play-post-content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <!-- <div class="meta-date">
          <span>{{ getDate(datas.created_At) }}</span>
        </div> -->
      </div>
      <h3 class="title">
        <a href="#">{{ datas.title }}</a>
      </h3>
    </div>
    <div v-if="datas.action === 'play'" class="play-btn">
      <a class="video-popup" @click.prevent="$store.dispatch('toggleVideo')"
        ><i class="fas fa-play"></i
      ></a>
    </div>
    <div v-if="datas.action === 'trending'" class="trending-btn">
      <a href="#"><i class="fas fa-bolt"></i></a>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
    },
    stype: {
      type: String,
    },
  },
  methods:{
    getImageUrl(url) {
      return "https://7br-dash.z-demos.com/" + url;
    },
    getDate(dateString){
      const changedDate = dateString.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
      var date = new Date(changedDate);
      return date.toLocaleDateString('ar-EG', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    })

    }
  }
};
</script>

<style></style>

<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <Header image="logo.png" @toggleSidebar="toggleSidebar" />
    <info />
    <div class="about-author-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                 contact-us
                  </li>
                </ol>
              </nav>
              <div class="author-content">
                <h3 class="title"> تواصل معنا:    </h3>
                <div class="text"> 
                <p>
                  نسعد دائماً بتواصلكم معنا ونتشرف باستقبال رسائلكم على البريد الإلكتروني
info@7br.online
                </p>
                <p>
                  وتسرنا متابعتكم لنا وتواصلكم على صفحات التواصل الاجتماعي
                  <a href="https://www.facebook.com/7br.online" target="_blank">
                    <img :src="require(`@/assets/images/facebook.png`)" alt="feature">
                  </a>
                 <a href="https://www.instagram.com/7br.online" target="_blank">
                  <img :src="require(`@/assets/images/instagram.png`)" alt="feature">
                 </a>
              
                </p>
                </div>
                <div class="logo-mid">
                  <img :src="require(`@/assets/images/logo.png`)" alt="feature">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-one />
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Header from "../Utility/Header/index";
import Info from "./Info.vue";
export default {
  components: { Header, Info,  FooterOne, Drawer },
  data: () => ({
    sidebar: false,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="contact-info-area">
    <div class="container">


    
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>

.heading {
  text-align: center;
}

.disc {
  text-align: center;
}

</style>

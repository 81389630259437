<template>
  <section class="feature-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div :class="['section-title', dark ? 'section-title-2' : '']">
            <h1 class="title center-text">{{$t('Trending_News')}}</h1>
          </div>
        </div>
      </div>
     
    </div>
  </section>
</template>

<script>
import post from "../Data/post";
export default {
  props: {
    dark: {
      type: Boolean,
    },
  },
  data: () => ({
    //feature
    post: post.data,
    featureSliderSettings: {
      slidesToShow: 4,
      slideToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slideToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slideToScroll: 1,
          },
        },
      ],
    },
  }),
  methods: {
    //feature
    featureSliderNext() {
      this.$refs.featureSlider.next();
    },
    featureSliderPrev() {
      this.$refs.featureSlider.prev();
    },
  },
};
</script>

<style scoped>
.center-text {
  text-align: center;

}
.title{
  font-size: 40px !important ;
  font-weight: 800 !important;
}
</style>

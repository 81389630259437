<template>
  <DarkTwo />
</template>

<script>
import DarkTwo from "../components/HomeDark/_v2/index";
export default {
  components: { DarkTwo },
};
</script>

<style>
</style>
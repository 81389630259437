<template>
  <div class="header-topbar" dir="rtl">
    <div class="container">
     
      
          <div class="trending">
            <p class="padge">
              الأحدث
            </p>
            <div class="slider">

              <slider :settings="settings" ref="carousel" v-if="$store.state.all_posts.length >0">
                <template v-for="(data, index) in $store.state.all_posts.slice(0,8)">
                  <div :key="index" class="item">
                    <p>{{ data.title }}</p>
                  </div>
                </template>
                            </slider>
              <span @click="prev" class="arrow" style="display: block">
                <i class="fal fa-angle-double-left"></i> 
              </span>
              <span @click="next" class="arrow right-arrow" style="display: block">
                <i class="fal fa-angle-double-right"></i>
              </span>
            </div>
          </div>
        
       
          <div class="topbar-date ">
            <p>{{ currentDate }}</p>
    
         </div>
         
    </div>
      </div>
 
</template>


<script>
import Slider from "../../Helpers/Slider.vue";
// import json from "../../Data/TrandingHeaderOneTopBar";
export default {
  components: { Slider },
  data: () => ({
    settings: {
      dots: false,
      arrows: false,
      autoplay: false,
    },
    // news: this.store.state.all_posts,
    currentDate: new Date().toLocaleDateString('ar-EG', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    })

  }),
  methods: {
    next() {
      this.$refs.carousel.next();
    },
    prev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>




<style scoped>

.container{
  min-height: 80px;
  display: flex;
 justify-content: space-around;
 flex-wrap: nowrap;
  border-bottom: 2px solid #333;
 box-shadow: #333 ;
 max-width: 100%;
}
.trending{
position: relative;
display: flex;
align-items: flex-end;
}
.padge{
  background: #ECB33B;
  color: #601970;
  font-size: 20px;
  font-weight: 500;
  padding: 18px 24px;
  margin: 0;
}

.slider{


  position: relative;
  width: 60vw;
  line-height: 30px;
  cursor: pointer;
  background: #fff;
  height: 80px;
  padding: 36px 40px 0 0;
  text-align: right;

}


p{
  font-size: 1.3rem !important;
  font-weight: bold !important;
  color:black;
  
}

i{
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  padding-top: 13px;
  color:black;
 
}

.arrow {

position: absolute;
z-index: 9;
border: 2px solid #909da9;
height: 50px;
width: 50px;
text-align: center;
line-height: 30px;
cursor: pointer;
background: #fff;
top: 25px;
left: 0;

}

.right-arrow{
  left: 80px;
}
.topbar-date{
  display: flex;
  flex-direction: column-reverse;

}
</style>


<template>
  <section>
  <div class="text-center mt-5">
    <!-- <a href="#">
      <img class="logo-top" src="@/assets/images/cover.png" alt="" />
    </a> -->
    <h1 class="heading"> انضم الى الفريق</h1>
    <p class="disc"> للانضمام الى فريق العمل الحقول المشار اليها ب * مطلوبة</p>
  </div>
  <div class="container-sm">
    <div class="form-container">
      <p class="header-text">
        انضم لفريقنا وكن نبضًا من نبضات حبر أون لاين، واجعل حبرك يخط إبداعاتك في أي مجال ترغب به وتبدع بالكتابة فيه من
        العلوم والآداب والمهارات العامة. سجّل بياناتك معنا وابدأ رحلة الكتابة الإبداعية.
      </p>
      <form @submit.prevent="handleSubmit" class="form-section">
        <div class="row form-group-row">
          <div class="form-group col-6">
            <label for="name">الاسم الكامل *</label>
            <input type="text" id="firsnametName" v-model="form.name" required />
          </div>
          <div class="form-group col-6">
            <label for="phone">رقم الهاتف *</label>
            <div class="tel-field">
              <input type="text" id="phone" v-model="form.phone" class="right"  required/>
              <vue-country-code   class="left" 
                      @onSelect="onSelect"
                  :preferredCountries="['sy']">
              </vue-country-code>
            </div>
          
          </div>
        </div>
        <div class="row form-group-row">
          <div class="form-group col-6">
            <label for="email">البريد الالكتروني *</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>
          <div class="form-group col-6">
            <label for="country">الدولة</label>
            <country-select v-model="form.country" :country="form.country"
            placeholder="اختر الدولة" className="my-select"
            countryName topCountry="SY" />
            
          </div>
        </div>
        <div class="row form-group-row">
          <div class="form-group col-12">
            <label for="about">عرف عن نفسك</label>
            <textarea id="about" v-model="form.about"></textarea>
          </div>
          <div class="form-group col-12">
            <label for="department">قسم العمل *</label>
            <select id="department" v-model="form.department" required>
              <option disabled value="">اختر قسم العمل</option>
              <option :value="category.name"  v-for="category in $store.state.categories" :key="category.name">{{ category.name }}</option>
              <!-- أضف هنا خيارات الأقسام -->
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button type="submit">إرسال</button>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 d-flex">
            <spinner v-show="sending" />
            <span v-html="result"></span>
          </div>
        </div>
      </form>

    </div>
    <div class="image-container">
      <div class="logo-box">
        <img class="logo" src="@/assets/images/Screenshot.png" alt="" />
      </div>
      <div class="social-icons">
        <a href="#"><i class="fa fa-facebook social-icons"></i></a>
        <a href="#"><i class="fa fa-twitter social-icons"></i></a>
        <a href="#"><i class="fa fa-instagram social-icons"></i></a>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import spinner from '../Helpers/Spinner.vue';
export default {
  data() {
    return {
      form :{
      name: '',
      phone: '',
      email: '',
      country: '',
      about: '',
      department: '',
      code : ''
      },
      sending : false,
      result : ''
    };
  },
  components:{
    spinner
  },
  async mounted(){
    await this.$store.dispatch("fetchCategories"); 
  },  
  methods: {
    onSelect({name, iso2, dialCode}) {
     console.log(name, iso2, dialCode);
     this.form.code = dialCode;
   },
    handleSubmit() {
      this.result ="";
      this.sending = true;
      this.form.phone = this.form.code +this.form.phone;
      const requestOptions = {
        method : "POST",
        headers : { "Content-Type" : "application/json"},
        body : JSON.stringify(this.form)
      };
      fetch("https://7br-dash.z-demos.com/api/About/SendContact",requestOptions)
      .then(async response =>{
        const data = await response.json();
        if(data === true){
         this.form.name = "";
         this.form.phone = "";
         this.form.about = "";
         this.form.country = "";
         this.form.email = "";
         this.form.department = "";
         this.form.code = '';
         this.result = "تم إرسال المعلومات بنجاح، سيتم التواصل معكم بأقرب وقت . شكرا لتواصلكم.";
         this.sending = false;
        }
        else{
          this.result = "عذرا لقد حصل خطأ";
          this.sending = false;
        }
      })
      }
  }
};
</script>

<style scoped>
.heading {
  font-size: 40px;
  font-weight: 700;
}

.disc {
  font-size: 22px;
  font-weight: 700;
}


.form-section {
  padding: 5% 12%;

}

.form-group-row {
  padding-top: 2%;
}

.container-sm {
  display: flex;
  justify-content: space-between;
  background-color: #3b005a;
  color: white;
  padding: 20px;
  direction: rtl;
}

.form-container {
  width: 60%;
}

.image-container {
  width: 40%;
  background-color: #fbbf39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.header-text {
  margin-bottom: 20px;
  font-size: 25px;
  text-align: center;
  padding: 5%;
  line-height: 3;
  font-weight: 600;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  text-align: start;
  font-size: 20px;
  font-weight: 600;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

button {
  background-color: #fbbf39;
  color: #3b005a;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  margin-top: 10%;
}

button:hover {
  background-color: #e6b800;
}

.logo {
  width: 100%;
}

.logo h1 {
  font-size: 48px;
}

.social-icons a {
  color: #3b005a;
  font-size: 30px;
  margin: 0 10px;
}

@media (min-width: 1140px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px;
  }

  .logo-top {
    width: 1400px;
}
}
.d-flex{
  display:flex;
  justify-content: center;
}
.tel-field{
  position:relative;
}
.right{
position :absolute;
right:0px;
}
.left{
  position :absolute;
  left:0px;
  height: 55px;
}
</style>

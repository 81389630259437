<template>
    <div>
      <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
      <Header @toggleSidebar="toggleSidebar" />
      <Agree />
      <div class="add-area text-center">
        <a href="#">
          <img src="@/assets/images/7br-app.jpg" alt="" />
        </a>
      </div>
      <footer-one />
      <div class="go-top-area">
        <div class="go-top-wrap">
          <div class="go-top-btn-wrap">
            <a href="#" class="go-top go-top-btn d-block">
              <i class="fa fa-angle-double-up"></i>
              <i class="fa fa-angle-double-up"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Drawer from "../Mobile/Drawer.vue";
  import FooterOne from "../Utility/Footer/FooterOne.vue";
  import Header from "../Utility/Header/index";
  import Agree from "./agree.vue";
  export default {
    components: {
      Header,
      FooterOne,
      Drawer,
      Agree
    },
    data: () => ({
      sidebar: false,
    }),
    created() {
      document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
      toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
      topToBottom() {
        const result = document.querySelector(".go-top");
        if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
        ) {
          result.classList.add("active");
        } else {
          result.classList.remove("active");
        }
      },
    },
  };
  </script>
  
  <style></style>
  
<template>
  <HomeThreeTwo />
</template>

<script>
import HomeThreeTwo from "../components/HomeThree/_v2/index";
export default {
  components: { HomeThreeTwo },
};
</script>

<style>
</style>
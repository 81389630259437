<template>
  <joinus />
</template>

<script>
import joinus from "../components/JoinUs/index";
export default {
  components: { joinus },
};
</script>

<style></style>

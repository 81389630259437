<template>
  <section class="video-news-area">
    <!-- التصنيفات -->
    <NewsCategory
      v-for="category in $store.state.categories"
      :key="category.name"
      :category="category.name"
      :slug="category.slug"
      :posts="category.posts"
    />
  </section>
</template>

<script>
import NewsCategory from '../NewsCategory.vue';

export default {
  components: {
    NewsCategory,
  },
  data() {
    return {

     
    };
  },
};
</script>

<style scoped>
.video-news-area {
  padding: 20px;
  background-color: #ffffff;
  height: auto;
}
</style>

<template>
  <div class="news-category" v-if="posts.length > 0">
    <router-link   :to="{ name: 'category', params: { slug: slug,page:1} }">
      <h2>{{ category }}</h2>
    </router-link>
    <div class="news-list home">
      <div v-for="post in posts" :key="post.id" class="news-item home">
        <img v-if="post.imagePath" :src="getImageUrl(post.imagePath)" class="post-img" />
        <img v-else src="@/assets/images/7br-placeholder.png" alt="" class="post-img" />
        <div class="news-info">
          <div class="post-title">
            <h4>{{ post.title }}</h4>
          </div>
        </div>
        <!-- <div class="news-info">
          <div class="post-content home">
            <p v-html="withBrTags(post.content)"></p>
          </div>
        </div> -->
        <div class="post-link">
            <router-link :to="{ name: 'post', params: { postSlug: post.slug } }"
            @click.native="viewMore(post.slug)">
            <button>
              متابعة القراءة
            </button>
          </router-link>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: String,
    slug: String,
    posts: Array,
  },
  methods: {
    getImageUrl(url) {
      return "https://7br-dash.z-demos.com/" + url;
    },
    viewMore(postSlug) {
      this.$store.dispatch('fetchPostBySlug', { postSlug });
    },
    withBrTags(content) {
      const index = content.indexOf("\\n");
      const newContent = content.substring(0, index).replace(/(?:\\r\\n|\\r|\\n)/g, '<br>');
      return newContent;
    }
  }
};
</script>

<style scoped>
.news-category {
  margin-bottom: 20px;
  text-align: right;
}

.news-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.news-item {
  /* background-color: #111;
  color: #fff; */
  color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 15px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;

  width: 100%;
}
.news-item.home {
  flex-basis: 30%;
  flex-direction: column;
  justify-content: space-between;
}

/* @media ( max-width: 1200px) {
  .news-item.home {
    flex-basis: 100%;
  }
} */
@media ( max-width: 992px) {
  .news-item.home {
    flex-basis: 100%;
  }
}

@media ( max-width: 320px) {
  .news-item.home  {
    flex-basis: 100%;
  }
}
.news-item img {
  border-radius: 8px;
  /* object-fit: cover;
  object-position: center; */
  height: 200px;
  width:100%;
  margin-bottom: 10px;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 399px) { 
  .news-item img {
    height: 150px;
  }
}
.news-item img:hover {
  transform: scale(1.1); 
}
.news-info {
  margin-top: 10px;
}

button {
  background-color: #6c3ea5;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #532d81;
}

</style>

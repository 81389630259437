<template>
  <footer class="footer mt-5">
    <div class="footer-content" dir="rtl">
      <div class="footer-section company-info">
        <div class="footer-row">
          <div class="footer-col footer-img">
           <div class="mb-1">
            <img :src="require(`@/assets/images/logo.png`)" alt="feature" />
           </div>
           <div>
            <p>
يهدف خبر أون لاين لأن يجمع عبر صفحاته كلّ ما يهم القارئ العربي وفي كلّ المجالات، ليبقى على اطلاع واسع على أهم المتجرات والأفكار الأدبية والفنية والعلميّة، منجمع في قضاء المدونة شمل محتوى القراءة مع محتوى الكتابة، كما يمكنا، أن يكون جزءا منها بمساهمته ومشاركته في تكوين المدونة وإغناء محتواها
          
        </p>
           </div>
        
          </div>
          <div class="footer-col">
            <div class="footer-section info">
        <ul>
          <li class="footer-header"><h6>الصفحات</h6></li>
          <li><router-link to="/">الرئيسية</router-link></li>
          <li><router-link to="/archive">مقالات</router-link></li>
          <li><router-link to="/contact">انضم للفريق</router-link></li>
          <li><router-link to="/about-us">حول</router-link></li>
          <li><router-link to="/terms">اتفاقية الاستخدام</router-link></li>
          <li><router-link to="/privacy">سياسة الخصوصية</router-link></li>
        </ul>
      </div>
          </div>
          <div class="footer-col footer-articles">
            <div class="footer-section latest-articles">
        <ul>
          <li class="footer-header"><h6>آخر المقالات</h6></li>
          <li  v-for="post in all_posts.slice(0, 3)" :key="post.id">
            <router-link :to="{ name: 'post', params: { postSlug: post.slug} }">
              {{ post.title }}</router-link>
          </li>
        </ul>
      </div>
          </div>
          <div class="footer-col">
                  <div class="footer-section follow-us footer-header folllow-us">
                    <h6>تابعنا على</h6>
        <div class="social-icons">
                  <a href="https://www.facebook.com/7br.online" target="_blank">
                    <img :src="require(`@/assets/images/facebook.png`)" alt="feature">
                  </a>
                 <a href="https://www.instagram.com/7br.online" target="_blank">
                  <img :src="require(`@/assets/images/instagram.png`)" alt="feature">
                 </a>
        </div>
      </div>
    </div>
          </div>
      
        </div>
        <div class="footer-bottom">
      <h6>كل الحقوق محفوظة @2024 zanobia.me</h6>
    </div>
      </div>
    
    

  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed :{
    all_posts: function () {
            return this.$store.state.all_posts
        },
},
};
</script>

<style scoped>
.footer {
  background-color: white;
  color: black;
  text-align: center;
  padding:25 100px;
  margin:10px;

}
p,a,i{
  color:black;
}
p{
  text-align: right;
}

.footer-content {
  /* display: flex;
  justify-content:space-evenly;
flex-direction: column; */
}
ul {
  list-style:none;
}
ul li{
  text-align:right;
}
.footer-section {
  margin-bottom: 20px;
  
}

.info, .latest-articles, .follow-us {
  /* display: flex;
  flex-direction: column; */
}

.social-icons {
  color:black !important;
}

.footer-bottom {
  margin-top: 20px;
}
.footer-bottom > h6 {
text-align: center;
}
.follow-us >h6{
  text-align: center;
}
/* .footer-content .footer-section {
  max-width:20%;
} */

 .footer-header >h6 , .footer-header{
  color:#800080;
  font-size: large;
  font-size: 24px;
 }
.company-info p{
  font-size: 12px;
}
.footer-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}
.footer-col{
  width:100%;
  margin:10px;
}
 a :hover{
color: #ECB33B !important;
}
@media (min-width: 600px)and(max-width: 900px) {
  .footer-row{
  display: flex;
  flex-direction: column;
}

}
@media (max-width: 600px) {
  .footer-row{
  display: flex;
  flex-direction: column;
}

  }
</style>

<template>
  <router-link :to="{ name: 'post', params: { postSlug: datas.slug } }" class="d-block">
    <div class="post_gallery_play">
      <div class="bg-image">
        <img v-if="datas.imagePath" :src="getImageUrl(datas.imagePath)" alt=""  style="width: 100%; height: 100%"/>
        <img v-else src="@/assets/images/7br-placeholder.png" alt=""  style="width: 100%; height: 100%" />
      </div>
      <div class="post__gallery_play_content" style="z-index: 10">
        <div class="post-meta">
          <div class="meta-categories">
            <a href="#">{{ datas.category.name }}</a>
          </div>
          <div class="meta-date">
            <span>{{ getDate(datas.created_At)}}</span>
          </div>
        </div>
        <h2 class="title">
          <a href="#">{{ datas.title }}</a>
        </h2>
      </div>
   
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["datas"],
  methods:{
    getImageUrl(url) {
      return "https://7br-dash.z-demos.com/" + url;
    },
    getDate(dateString){
      const changedDate = dateString.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
      var date = new Date(changedDate);
      return date.toLocaleDateString('ar-EG', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    })

    }
  }
};
</script>

<style scoped>

</style>

<template>
    <div>
      <category-layout>
      <section class="about-item-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="about-author-content pt-15">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item"><a href="#">Category</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{slug}}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="about-tab-btn mt-40">
                <div class="archive-btn">
                  <ul>
                    <li>
                      <span>التصنيف: <span>
                        {{$store.state.currentCategory.name}}

                      </span></span>
                    </li>
                  </ul>
                </div>
                <div class="about-post-items">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="business-post-item mb-40" 
                      v-for="post in posts"
                       :key="post.id">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img v-if="post.imagePath" :src="getImageUrl(post.imagePath)" alt=""/>
                              <img v-else src="@/assets/images/7br-placeholder.png" alt="" />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">
                                      {{$store.state.currentCategory.name}}
                                    </a>
                                  </div>
                                  <div class="meta-date">
                                    <span>{{post.created_At  | moment("ddd, MMMM YYYY")}}</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link :to="{ name: 'post', params: { postSlug: post.slug } }">
                                    {{post.title}}
                                    </router-link
                                  >
                                </h3>
                                <p class="text" v-html="withBrTags(post.content)">
                              
                                </p>
                                <router-link :to="{ name: 'post', params: { postSlug: post.slug } }" >متابعة القراءة</router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="pagination-item">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li  v-bind:class = "(page != 1 )?'page-item active':'page-item'">
                              <button :disabled="page == 1"  class="page-link" @click="goToPage(1)">1</button>
                            </li>
                            <li v-bind:class = "(hasPreviousPage)?'page-item active':'page-item'">
                              <button :disabled="!hasPreviousPage" class="page-link" @click="previousPage()">السابق</button>
                            </li>
                            <li class="page-item" v-bind:class = "(hasNextPage)?'active':'page-item'">
                              <button :disabled="!hasNextPage" class="page-link" @click="nextPage()">التالي</button>
                            </li>
                            <li  v-bind:class = "(page != totalPages )?'page-item active':'page-item'">
                              <button :disabled="page == totalPages"  class="page-link" @click="goToPage(totalPages)">الأخيرة</button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <style-one :datas="bulk.slice(1, 6)" :sharePost="false" />
            </div>
          </div>
        </div>
      </section>
    </category-layout>
    </div>
  </template>
  
  <script>
   import StyleOne from "../Utility/Sidebar/StyleOne.vue";
  import CategoryLayout from "./CategoryLayout.vue";
   import Posts from "../Data/TrendingHomeThree";
  export default {
     components: { CategoryLayout, StyleOne },
    props: ['slug','page'],
    data: () => ({
       bulk: Posts.data
    }),
  async mounted(){
    await this.$store.dispatch("fetchAllPosts",{count:20});
    await this.$store.dispatch("fetchFeaturedPosts",{count:0});
    await this.$store.dispatch("fetchTopViewsPosts",{count:3});
  },
computed :{
  posts: function () {
            return this.$store.state.page_posts.data
        },
    hasNextPage : function () {
            return this.$store.state.page_posts.hasNextPage
        },
        hasPreviousPage : function () {
            return this.$store.state.page_posts.hasPreviousPage
        },
        totalPages : function () {
            return this.$store.state.page_posts.totalPages
        }
},

  watch: {
    async '$route.params.page' () {
      // react to route changes...
      await this.$store.dispatch("fetchPagePosts",{categorySlug:this.slug,page:this.page});
    }
  },
  async created(){
    await this.$store.dispatch("fetchCategory",{slug:this.slug});
    await this.$store.dispatch("fetchPagePosts",{categorySlug:this.slug,page:this.page});
  },
  methods:{
    nextPage(){
      this.$router.replace({name: 'category', params: {page: +this.page+1}});
    },
    previousPage(){
      this.$router.replace({name: 'category', params: {page: +this.page-1}});
    },
    goToPage(p){
      this.$router.replace({name: 'category', params: {page: +p}});
    },
    withBrTags(content) {
      const index = content.indexOf("\\n");
      const newContent = content.substring(0, index).replace(/(?:\\r\\n|\\r|\\n)/g, '<br>');
      return newContent;
    },
    getImageUrl(url) {
      return "https://7br-dash.z-demos.com/" + url;
    },
  }
  };
  </script>
  
  <style scoped>
.cat-desc{
text-align : right;
}
</style>
  
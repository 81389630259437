<template>
  <div>
    <!-- <video-pop-up /> -->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <!--====== HEADER PART START ======-->
 <header-one image="logo.png" @toggleSidebar="toggleSidebar" />
    <!--====== HEADER PART ENDS ======-->
   
    
     <!--====== SearchBar PART START ======--> 
    <SearchBar/>
     <!--====== SearchBar PART ENDS ======--> 


    <!--====== POST PART START ======-->
<!-- 
    <post-part-home-one /> -->

    <!--====== POST PART ENDS ======-->

    <!--====== POST GALLERY PART START ======-->
    <post-gallery/>

    <!--====== POST GALLERY PART ENDS ======-->

    <!--====== FEATURE PART START ======-->
    <features />

    <!--====== FEATURE PART ENDS ======-->

    <!--====== TRENDING NEWS PART START ======-->
    <!-- <trending-news /> -->

    <!--====== TRENDING NEWS PART ENDS ======-->

    <!--====== SINGLE PLAY POST PART START ======-->
    <single-play-post />

    <!--====== VIDEO NEWS PART START ======-->

    <video-news />

    <!--====== VIDEO NEWS PART ENDS ======-->

    <!--====== ALL POST PART START ======-->

    <home-one-post-area />

    <!--====== ALL POST PART ENDS ======-->
    <!--====== join us start ======-->
    <JoinUsBanner/>
    <!--====== join us end ======-->
    <!--====== FOOTER PART START ======-->
    <footer-one />

    <!--====== FOOTER PART ENDS ======-->

    <!--====== GO TO TOP PART START ======-->
    <back-to-top />
    <!--====== GO TO TOP PART ENDS ======-->
  </div>
</template>
<script>
import HeaderOne from "../Utility/Header/index";
import SearchBar from './SearchBar.vue';
// import PostPartHomeOne from "./PostPartHomeOne.vue";
import PostGallery from "./PostGallery.vue";
import Features from "./Features.vue";
// import TrendingNews from "./TrendingNews.vue";
import SinglePlayPost from "./SinglePlayPost.vue";
import VideoNews from "./VideoNews.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Drawer from "../Mobile/Drawer.vue";
// import VideoPopUp from "../Utility/VideoPopUp.vue";
import HomeOnePostArea from "../_Containers/HomeOnePostArea.vue";
import BackToTop from "../Helpers/BackToTop.vue";
import JoinUsBanner from "./joinusbanner.vue";
export default {
  components: {
     HeaderOne,
     SearchBar,
    // PostPartHomeOne,
    PostGallery,
    Features,
    // TrendingNews,
    SinglePlayPost,
    VideoNews,
    FooterOne,
    Drawer,
   // VideoPopUp,
    JoinUsBanner,
    HomeOnePostArea,

    BackToTop,
  },
  data: () => ({
    sidebar: false,
  }),
  async mounted(){
    await this.$store.dispatch("fetchAllPosts",{count:20});
    await this.$store.dispatch("fetchFeaturedPosts",{count:0});
    await this.$store.dispatch("fetchTopViewsPosts",{count:3});
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style scoped>
div{
  background: #fff !important;
}
.single-play-post-area .single-play-box {
  padding: 23px 12px !important;
}
</style>

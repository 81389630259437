<template>
    <div class="about-author-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                 policy
                  </li>
                </ol>
              </nav>
              <div class="author-content">
                <h3 class="title">  اتّفاقيّة الاستخدام:    </h3>
                <div class="text"> 
                  <p>
                    باستخدامك لمدوّنة حبر أون لاين بأيّة وسيلة كانت، فأنت تقرّ بأنّك قرأت بعناية هذه الاتّفاقيّة وبأنّك موافق على كلّ ما ورد فيها.
  </p>
  <p>
    <ul>
      <li>
        1-	توفّر المدوّنة إمكانيّة التّعليق على المحتوى المنشور فيها، ويحقّ لكلّ المستخدمين المشاركة في التّعليق على ألّا يكون تعليقاً مسيئاً أو مخالفاً أو محرّضاً أو تشهيريّا وما شابه ذلك، وتحتفظ المدوّنة بحقّ حذف أو عدم نشر أيّ تعليق لا يتناسب مع الشّروط.
      </li>
      <li>
        2-	إنّ المحتوى المتوفّر من مقالات ومعلومات وتصاميم وغيرها محفوظة لإدارة المدوّنة، وهي وحدها بإدارتها وفريقها تحتفظ بحقوق الملكيّة لكلّ المحتوى المنشور فيها.
    </li>
      <li>
        3-	إنّ استعراض أو طباعة أو تحميل أي محتوى مكتوب أو مصوّر متاح فقط للاستخدام الشّخصي أو لأغراض التّعليم غير الرّبحي، ولا يجوز إعادة نشر أو توزيع أي من المحتوى المتوفّر في المدوّنة ضمن أيّ شكل من الأشكال دون الإشارة إلى وروده ضمن المدوّنة كمصدر أو مرجع له.
      </li>
   
      <li>
        4-	تحتفظ إدارة المدوّنة بحقّ تحرير أو حذف أو تعديل أيّة وثيقة أو معلومة ضمن محتواها وفي أيّ وقت.
      </li>
      <li>
        5-	يفضّل عند التّعليق على أي محتوى استخدام الاسم الشّخصي وبريد إلكتروني صحيح وتحترم المدوّنة هذه المعلومات ولا تستخدمها لأغراض أخرى.
      </li>
      <li>
        6-	قد تتغيّر هذه البنود أو يجري تعديل عليها بين فترة وأخرى بما يتناسب مع أهداف المدوّنة، ولا يتوجّب على المدوّنة إعلامك بهذه التّعديلات، لذلك ينصح بزيارة صفحة سياسة الخصوصيّة واتّفاقيّة الاستخدام بشكل مستمرّ للاطّلاع على هذه التّحديثات.
      </li>
    </ul>
  
                  </p>
                </div>
                <div class="logo-mid">
                  <img :src="require(`@/assets/images/logo.png`)" alt="feature">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style>
  .author-content .thumb {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    overflow: hidden;
    height: 115px;
    width: 115px;
    text-align: center;
    line-height: 112px;
    border: 2px solid #e4e5e6;
  }
  .logo-mid{
    align-items: center;
    display: flex;
    justify-content: center;
  }
    .about-author-content .author-content ul li{
      margin-right: 20px !important;
  }
  </style>
  
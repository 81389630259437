<template>
    <div class="container">
      <div class="search-bar">
        <button>
            <i class="fa fa-search"></i> 
        </button>
      <input type="text" v-model="searchQuery" class="large" placeholder="ابحث في حبرنا عن لونك" />
      <input type="text" v-model="searchQuery" class="small" placeholder="ابحث في حبرنا" />
    </div>      
    </div>

  </template>
  
  <script>
  export default {
    data() {
      return {
        searchQuery: ''
      };
    },
    methods: {
      performSearch() {
       
      }
    }
  };
  </script>
  
  <style scoped>
  input.large { display: inline-block; }
input.small { display: none; }

@media (max-width: 399px) { 
  input.large { display: none; }
  input.small { display: inline-block; }
}
.container{
    display:flex;
    justify-content: center;
    padding:30px 0 !important;
}
  .search-bar{
  width:50%;
  min-width:300px;
    border-radius: 5px 15px;
    border: 1px ridge rgb(4, 1, 24);
    border-bottom: 2px ridge rgb(4, 1, 24);
    padding: 10px;
 display:flex;
 justify-content: flex-start;

  }
  .search-bar input {
    outline-offset: 0;
    min-width: 80%;
    width: 100% ;
    border: none;
    outline: none;
    line-height: 40px;
    font-size: 30px; 
    text-align: center;
  }
  

  .search-bar input::placeholder {
    text-align: center;
    font-size: 30px; 
  }
  .search-bar button {
    border: none;
    background: none;
    cursor: pointer;
  }
  i{
    font-size: 34px;

  }
  </style>
  
// main.js
import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import router from './router';
import store from './store';
import en from './locales/en.json';
import ar from './locales/ar.json';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/default.css';
import './assets/css/style.css';
import './assets/css/custom.css';
import vueCountryRegionSelect from 'vue-country-region-select';
import VueCountryCode from "vue-country-code";

Vue.use(vueCountryRegionSelect)
Vue.use(VueCountryCode);
Vue.config.productionTip = false;

Vue.use(VueI18n);

const messages = {
  en,
  ar,
};

const i18n = new VueI18n({
  locale: 'ar',
  fallbackLocale: 'ar',
  messages,
});
Vue.use(require('vue-moment'));
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
// main.js

//new Vue({
  //i18n,
 // render: h => h(App),
 // watch: {
 //   '$i18n.locale'(newLocale) {
    //  document.documentElement.dir = newLocale === 'ar' ? 'rtl' : 'ltr';
   // }
//  }
//}).$mount('#app');

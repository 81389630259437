<template>
  <div
    :class="[
      'post_gallery_sidebar',
      darkClass && 'section' in darkClass ? darkClass.section : '',
    ]"
  >
    <ul class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav-item" @click.prevent="selectGalleryTab('trendy')">
        <a
          :class="['nav-link', selectedGallery === 'trendy' ? 'active' : '']"
          data-toggle="pill"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
          >{{$t('Common')}}</a
        >
      </li>
      <li class="nav-item" @click.prevent="selectGalleryTab('latest')">
        <a
          :class="['nav-link', selectedGallery === 'latest' ? 'active' : '']"
          data-toggle="pill"
          href="#pills-profile"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false">{{ $t('Featured') }}</a
        >
      </li>
      <li class="nav-item" @click.prevent="selectGalleryTab('popular')">
        <a
          :class="['nav-link', selectedGallery === 'popular' ? 'active' : '']"
          class="nav-link"
          data-toggle="pill"
          href="#pills-contact"
          role="tab"
          aria-controls="pills-contact"
          aria-selected="false"
          >{{ $t('latest_news') }}</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        :class="[
          'tab-pane fade',
          selectedGallery === 'trendy' ? 'show active' : '',
        ]"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="post_gallery_items" v-if="featured_posts && featured_posts.length > 0">
          <template v-for="(small, index) in featured_posts.slice(0, 2)">
            <row-card
              :class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
              :category="true"
              :datas="small"
              :key="index"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="tab-content">
      <div
        :class="[
          'tab-pane fade',
          selectedGallery === 'latest' ? 'show active' : '',
        ]"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div v-if="featured_posts && featured_posts.length > 0" class="post_gallery_items">
          <template v-for="(small, index) in featured_posts.slice(0, 2)">
            <row-card :category="true" :datas="small" :key="index" />
          </template>
        </div>
      </div>
    </div>
    <div class="tab-content">
      <div
        :class="[
          'tab-pane fade',
          selectedGallery === 'popular' ? 'show active' : '',
        ]"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div v-if="featured_posts && featured_posts.length > 0" class="post_gallery_items">
          <template v-for="(small, index) in featured_posts.slice(0,2)">
            <row-card :category="true" :datas="small" :key="index" />
          </template>
        </div>
      </div>
    </div>
    <div v-if="ad" class="sidebar-add pt-35">
      <a href="#">
        <img src="@/assets/images/7br-placeholder.png" alt="ad" />
      </a>
    </div>
    <!-- <share-post v-if="sharePost" class="mt-40" /> -->
  </div>
</template>

<script>
//import Slider from "../../Helpers/Slider.vue";
// import store from "../../../store";
// import SharePost from "../../Home/SharePost.vue";
//import DivideCard from "../Cards/DivideCard.vue";
import RowCard from "../Cards/RowCard.vue";
// import SignupStyleTwo from "../Signup/SignupStyleTwo.vue";
export default {
  components: { RowCard,
    //  SignupStyleTwo,
      //DivideCard, Slider,
      //  SharePost
       },
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
    darkClass: {
      type: Object,
      default: () => {},
    },
    signup: {
      type: Boolean,
      default: true,
    },
    trendingBigPost: {
      type: Boolean,
      default: true,
    },
    trendingShortPost: {
      type: Boolean,
      default: true,
    },
    ad: {
      type: Boolean,
      default: true,
    },
    sharePost: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedGallery: "trendy",
    trendingNews: {
      autoplay: true,
      slideToScroll: 1,
      slidesToShow: 1,
      arrows: false,
      dots: false,
    },
  }),
  async mounted(){

},
computed :{
  featured_posts: function () {
    if(this.selectedGallery == "trendy"){
        // common
      return this.$store.state.top_views_posts;
      } // featured
      else if(this.selectedGallery == "latest"){
       return this.$store.state.featured_posts;
      } // latest news
      else if(this.selectedGallery == "popular"){
        return this.$store.state.all_posts;
}
 else return  this.$store.state.featured_posts;
        },
},
  methods: {
    selectGalleryTab(value) {
      this.selectedGallery = value;
    },
    trendingNewsPrev() {
      this.$refs.trendingNews.prev();
    },
    trendingNewsNext() {
      this.$refs.trendingNews.next();
    },
  },
};
</script>

<style scoped>
.nav-item a{
  font-size: 30px !important;
  padding: 10px 20px !important;
}
.nav-item{

  /* box-shadow: 0px 18px 10px rgba(23, 34, 43, 0.1); */
  cursor: pointer ;
}
.nav{
  padding-right: 0 !important;
}
</style>

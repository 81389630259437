<template>
  <div class="home-container">
 
    <div class="text-above-button">
      انضم لفريقنا وكن نبضاً من نبضات حبر أون لاين واجعل حبرك
      يخط إبداعك في أي مجال ترغبه وتبدع بالكتابة فيه من العلوم
      والآداب والمهارات العامة
      <p>😊</p>
    </div>
    
    <router-link to="/join-us" class="join-us-button">انضم الينا الأن</router-link>
  </div>
</template>


<script>

export default {};
</script>  

<style scoped>
.home-container {
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  background-color: purple !important; 
  padding:30px;
  text-align: center; 
  color: white; 
}

.text-above-button {
  margin-bottom: 20px; 
  font-size: 24px;
  font-weight: 600;
  padding:0 25%;
  line-height: 55px;
}
p{
  text-align: center;
}
.join-us-button {
  background-color: #d15ed7; 
  color: black; 
  padding: 10px 20px; 
  border: none;
  border-radius: 10px; 
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.join-us-button:hover {
  background-color: #89148f; 
}
@media (min-width: 600px)and(max-width: 900px) {

}
@media (max-width: 600px) {
  .text-above-button {
  font-weight: 400;
  padding:10px;
}
}
</style>

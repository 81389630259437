var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'post_gallery_sidebar',
    _vm.darkClass && 'section' in _vm.darkClass ? _vm.darkClass.section : '',
  ]},[_c('ul',{staticClass:"nav nav-pills",attrs:{"id":"pills-tab","role":"tablist"}},[_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.selectGalleryTab('trendy')}}},[_c('a',{class:['nav-link', _vm.selectedGallery === 'trendy' ? 'active' : ''],attrs:{"data-toggle":"pill","role":"tab","aria-controls":"pills-home","aria-selected":"true"}},[_vm._v(_vm._s(_vm.$t('Common')))])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.selectGalleryTab('latest')}}},[_c('a',{class:['nav-link', _vm.selectedGallery === 'latest' ? 'active' : ''],attrs:{"data-toggle":"pill","href":"#pills-profile","role":"tab","aria-controls":"pills-profile","aria-selected":"false"}},[_vm._v(_vm._s(_vm.$t('Featured')))])]),_c('li',{staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.selectGalleryTab('popular')}}},[_c('a',{staticClass:"nav-link",class:['nav-link', _vm.selectedGallery === 'popular' ? 'active' : ''],attrs:{"data-toggle":"pill","href":"#pills-contact","role":"tab","aria-controls":"pills-contact","aria-selected":"false"}},[_vm._v(_vm._s(_vm.$t('latest_news')))])])]),_c('div',{staticClass:"tab-content"},[_c('div',{class:[
        'tab-pane fade',
        _vm.selectedGallery === 'trendy' ? 'show active' : '',
      ],attrs:{"role":"tabpanel","aria-labelledby":"pills-home-tab"}},[(_vm.featured_posts && _vm.featured_posts.length > 0)?_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.featured_posts.slice(0, 2)),function(small,index){return [_c('row-card',{key:index,class:[_vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : ''],attrs:{"category":true,"datas":small}})]})],2):_vm._e()])]),_c('div',{staticClass:"tab-content"},[_c('div',{class:[
        'tab-pane fade',
        _vm.selectedGallery === 'latest' ? 'show active' : '',
      ],attrs:{"role":"tabpanel","aria-labelledby":"pills-home-tab"}},[(_vm.featured_posts && _vm.featured_posts.length > 0)?_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.featured_posts.slice(0, 2)),function(small,index){return [_c('row-card',{key:index,attrs:{"category":true,"datas":small}})]})],2):_vm._e()])]),_c('div',{staticClass:"tab-content"},[_c('div',{class:[
        'tab-pane fade',
        _vm.selectedGallery === 'popular' ? 'show active' : '',
      ],attrs:{"role":"tabpanel","aria-labelledby":"pills-home-tab"}},[(_vm.featured_posts && _vm.featured_posts.length > 0)?_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.featured_posts.slice(0,2)),function(small,index){return [_c('row-card',{key:index,attrs:{"category":true,"datas":small}})]})],2):_vm._e()])]),(_vm.ad)?_c('div',{staticClass:"sidebar-add pt-35"},[_vm._m(0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/images/7br-placeholder.png"),"alt":"ad"}})])
}]

export { render, staticRenderFns }
<template>
  <div class="about-author-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-author-content">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
               policy
                </li>
              </ol>
            </nav>
            <div class="author-content">
              <h3 class="title">  سياسة الخصوصيّة:    </h3>
              <div class="text"> 
                <p>
      
إذا كنت بحاجة إلى المزيد من المعلومات أو كانت لديك أيّة استفسارات أو أسئلة عن سياسة الخصوصيّة، تواصل معنا عبر البريد الإلكتروني info@7br.online
</p>
<p>
  نحن في حبر أون لاين نعتني بخصوصيّة زوارنا ونمنحها أهميّة بالغة، وتوجز هذه الوثيقة سياسة الخصوصيّة لكلّ أنواع المعلومات الشّخصيّة الّتي تجمعها المدوّنة وكيفيّة استخدامها.

  <ul>
    <li>
      -	 ملفّات الدّخول:
إنّ حبر أون لاين مثله مثل كلّ المواقع والمدوّنات الأخرى تشمل معلومات ملفّات الدّخول فيه على بروتوكول (IP)، عناوين الإنترنت، نوع المتصفّح، مزوّد خدمة الإنترنت (ISP)، طابع التّاريخ والوقت، الخروج، الصّفحات، عدد النّقرات لتحليل الاتّجاهات وإدارة حركة الموقع، تعقّب المستخدم حول الموقع، وجمع المعلومات الدّيمغرافيّة، ولا يرتبط أيّ من هذه المعلومات أو غيرها بأيّ شيء يحدّد هويّتك.

    </li>
    <li>
      -	الكوكيز وإعدادات الشّبكة:
لا يستخدم حبر أون لاين الكوكيز لتخزين المعلومات عن تفضيلات الزّوّار، أو معلومات المستخدم عن الزّيارة والوصول إلى الصّفحات، أو لإنشاء صفحات ويب استناداً إلى نوع المتصفّح أو ما يرسله الزّائر من معلومات عبره.

    </li>
    <li>
      -	كويز دبل كليك:
يستخدم غوغل كطرف ثالث الكوكيز لخدمة الإعلانات على حبر أون لاين وهو يمهّد بذلك لخدمة الإعلانات للمستخدمين استناداً إلى زياراتهم لحبر أون لاين وغيره من المواقع على شبكة الإنترنت، ويمكن للمستخدمين اختيار عدم استخدام الكوكيز DART الإعلانيّة للمحتوى والخصوصيّة في العنوان التّالي: http://www.google.com/privacy_ads.html
كما أنّه لا تستطيع حبر أون لاين استخدام هذه الكوكيز الّتي يستخدمها طرف ثالث أو الاطّلاع عليها، فهذه المعلومات تخدم شركات الإعلان الّتي يهمّها قياس نسبة وصول إعلانها ومتابعته.
كما أنّ حبر أون لاين لا يمكنها مراقبة أنشطة المعلنين أو مواقع الإنترنت، وإذا كنت ترغب في تعطيل الكوكيز فتسطيع فعل ذلك من خلال خصائص وإعدادات متصفّحك الفردي.

    </li>
    <li>
      -	القائمة البريديّة:
بتسجيلك في القائمة البريديّة لدى حبر أون لاين فإنّ خصوصيّة عنوان بريدك ستبقى محترمة ولن يتمّ نشره، والغرض منه هو خدمتك بتزويدك بأحدث محتوى يتوفّر على المدوّنة.

    </li>
  </ul>

                </p>
              </div>
              <div class="logo-mid">
                <img :src="require(`@/assets/images/logo.png`)" alt="feature">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.author-content .thumb {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
  height: 115px;
  width: 115px;
  text-align: center;
  line-height: 112px;
  border: 2px solid #e4e5e6;
}
.logo-mid{
  align-items: center;
  display: flex;
  justify-content: center;
}
  .about-author-content .author-content ul li{
    margin-right: 20px !important;
}
</style>

<template>
    <div class="tp-header-lang">
      <span class="tp-header-lang-selected-lang tp-lang-toggle" @click="toggleActive" id="tp-header-lang-toggle">
        {{ languages[selectedLanguage] }}
      </span>
      <ul class="tp-header-lang-list tp-lang-list" :class="{ 'tp-lang-list-open': activeClass }">
        <li v-for="(langName, langKey) in languages" :key="langKey" @click="changeLanguage(langKey)">
          {{ langName }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        selectedLanguage: 'ar',
        activeClass: false,
        languages: {
          en: 'English',
          ar: 'Arabic'
        }
      };
    },
    methods: {
      toggleActive() {
        this.activeClass = !this.activeClass;
      },
      changeLanguage(lang) {
        this.selectedLanguage = lang;
        this.$i18n.locale = lang;
        document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
        this.activeClass = false; // Close the dropdown after selecting a language
        // Load or remove RTL styles based on the selected language
        if (lang === 'ar') {
          this.loadRtlStyles();
        } else {
          this.removeRtlStyles();
        }
      },
      loadRtlStyles() {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/assets/css/rtl-styles.css';
        link.id = 'rtl-styles';
        document.head.appendChild(link);
      },
      removeRtlStyles() {
        const link = document.getElementById('rtl-styles');
        if (link) {
          link.remove();
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .tp-header-lang {
    position: relative;
    display: inline-block;
  }
  
  .tp-header-lang-selected-lang {
    cursor: pointer;
    /* Add other styles as needed */
  }
  
  .tp-lang-list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    /* Add other styles as needed */
  }
  
  .tp-lang-list-open {
    display: block;
    /* Add other styles as needed */
  }
  </style>
  
<template>
  <home-three />
</template>

<script>
import HomeThree from "../components/HomeThree/index";
export default {
  components: { HomeThree },
};
</script>

<style>
</style>
<template>
  <section class="trending-news-area">

  </section>
</template>

<script>
import TrendingPosts from "../Data/TrendingPost";
import smallPostGallery from "../Data/NewsRowCard";
export default {
  props: {
    darkClass: {
      type: Object,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    smallPostGallery: smallPostGallery.data,
    TrendingPosts: TrendingPosts.data,
    trendingSettings: {
      arrows: false,
      slidesToShow: 2,
      slideToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slideToScroll: 1,
          },
        },
      ],
    },
    trendingSidebarSlide: {
      arrows: false,
      slidesToShow: 1,
      slideToScroll: 1,
    },
  }),
  methods: {
    //trending
    trandingPrev() {
      this.$refs.trendingSlider.prev();
    },
    trandingNext() {
      this.$refs.trendingSlider.next();
    },
    tssPrev() {
      this.$refs.trendingSidebarSlide.prev();
    },
    tssNext() {
      this.$refs.trendingSidebarSlide.next();
    },
  },
};
</script>

<style></style>

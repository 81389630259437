<template>
  <HomeTwoThree />
</template>

<script>
import HomeTwoThree from "../components/HomeTwo/_v3/index";
export default {
  components: {
    HomeTwoThree,
  },
};
</script>

<style>
</style>
<template>
  <section class="video-news-area">
    <!-- التصنيفات -->
    <div class="categories-section">
      <h1>التصنيفات</h1>
      <div class="category-list">
        <div class="category-item">الكل</div>
        <div  v-for="cat in $store.state.categories" :key="cat.id"
          class="category-item">
         <router-link 
          :to="{ name: 'category', params: { slug: cat.slug,page:1} }">
            {{ cat.name }}
       </router-link>
      </div>
        <!-- <span class="category-item">
          <router-link to="/archive">
         الكل
       </router-link>
        </span>
        <span  v-for="category in $store.state.categories" :key="category.id" class="category-item">
          <router-link 
          :to="{ name: 'category', params: { slug: category.slug,page:1} }">
            {{ category.name }}
       </router-link>
        </span> -->
      </div>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    darkClass: {
      type: Object,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  async mounted(){
   await this.$store.dispatch("fetchCategories");
  },
  computed: {
    // getNumbers() {
    //   return this.$store.getters.getNumbers;
    // },
  },
};
</script>

<style scoped>
.video-news-area {
  background: linear-gradient(to bottom, #482473 0%, #ff8c00 100%);
  color: white;
  padding: 20px;
  font-family: 'Arial, sans-serif';
  /* height: 900px;  */
}

.categories-section {
  text-align: center;
  /* margin-top: 30%; */
}

.category-list {
  /* display: flex;
  flex-wrap: wrap; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.category-item {
  margin: 5px 10px;
  padding: 10px ;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(0, 0, 0); 
  font-size: 24px;
  font-weight: 800; 
  font-family: 'cairo';
}

.category-item:hover {
  background-color: #3d0f52;
  color:#fff ;
}
.category-item:hover > a {
  background-color: #3d0f52;
  color:#fff ;
}
.category-item-active{
  background-color: #3d0f52;
  color:#fff;
}
.categories-section > h1{
  text-align: center;
}
</style>

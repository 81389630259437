<template>
    <div>
      <Agree />
    </div>
  </template>
  
  <script>
  import Agree from "../components/Agreement/index.vue";
  export default {
    components: { Agree },
  };
  </script>
  
  <style></style>
  
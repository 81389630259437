<template>
  <div>
    <policy />
  </div>
</template>

<script>
import Policy from "../components/Policy/index";
export default {
  components: { Policy },
};
</script>

<style></style>

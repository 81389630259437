<template>
  <router-link :to="{ name: 'post', params: { postSlug: datas.slug } }"  v-if="category" class="gallery_item">
    <template v-if="datas">
      <div class="gallery-container">
        <div class="gallery_item_thumb">
        <img v-if="datas.imagePath" :src="getImageUrl(datas.imagePath)" alt="gallery"/>
        <img v-else src="@/assets/images/7br-placeholder.png" alt="gallery" />
      </div>
      <div class="gallery_item_content">
        <div class="post-meta">
          <div class="meta-categories">
            <a href="#">{{ datas.category.name }}</a>
          </div>
          <div class="meta-date">
            <span>{{getDate( datas.created_At)}}</span>
          </div>
        </div>
        <h4 class="title">
          <a href="#">{{ datas.title }}</a>
        </h4>
      </div>
      </div>
      
    </template>
  </router-link>
  <router-link
    :to="{ name: 'post', params: { postSlug: datas.slug } }" 
    v-else-if="countingtwo"
    class="post-gallery-style-2 most-view-style-2"
  >
  <div class="gallery-container">
    <div class="post-gallery-thumb">
      <img v-if="datas.imagePath" :src="getImageUrl(datas.imagePath)" alt="gallery"/>
      <img v-else src="@/assets/images/7br-placeholder.png" alt="gallery" />
      <span>{{ count }}</span>
    </div>
    <div class="post-gallery-content">
      <h5 class="title">
        <a href="#">{{ datas.title }}</a>
      </h5>
      <div class="meta-post-2-style">
        <div class="meta-post-categores">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-post-date">
          <span>{{getDate( datas.created_At)}}</span>
        </div>
      </div>
    </div>
    </div>
  </router-link>
  <router-link :to="{ name: 'post', params: { postSlug: datas.slug } }"  v-else-if="trending" class="gallery_item">
    <div class="gallery_item_thumb">
      <div class="gallery-container">
      <img v-if="datas.imagePath" :src="getImageUrl(datas.imagePath)" alt="gallery"/>
        <img v-else src="@/assets/images/7br-placeholder.png" alt="gallery" />
      <div v-if="datas.trending" class="icon"><i class="fas fa-bolt"></i></div>
    </div>
    <div class="gallery_item_content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-date">
          <span>{{getDate( datas.created_At)}}</span>
        </div>
      </div>
      <h4 class="title">
        <a href="#">{{ datas.title }}</a>
      </h4>
    </div>
  </div>
  </router-link>
  <router-link
    :to="{ name: 'post', params: { postSlug: datas.slug } }" 
    v-else-if="counting"
    class="gallery_item gallery_item-style-2"
  >  <div class="gallery-container">
    <div class="gallery_item_thumb">
      <img v-if="datas.imagePath" :src="getImageUrl(datas.imagePath)" alt="gallery"/>
      <img v-else src="@/assets/images/7br-placeholder.png" alt="gallery" />
      <div class="icon"><i class="fas fa-bolt"></i></div>
    </div>
    <div class="gallery_item_content">
      <div class="post-meta">
        <div class="meta-categories">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-date">
          <span>March 26, 2020</span>
        </div>
      </div>
      <h4 class="title">
        <a href="#">{{ datas.title }}</a>
      </h4>
      <span>{{ count }}</span>
    </div>
  </div>
  </router-link>
  <router-link :to="{ name: 'post', params: { postSlug: datas.slug } }"  v-else-if="sports" class="gallery_item">
    <template v-if="datas">
      <div class="gallery-container">
      <div class="gallery_item_thumb">
        <img v-if="datas.imagePath" :src="getImageUrl(datas.imagePath)" alt="gallery"/>
        <img v-else src="@/assets/images/7br-placeholder.png" alt="gallery" />
      </div>
      <div class="gallery_item_content">
        <div class="post-meta">
          <div class="meta-categories">
            <a href="#">{{ datas.category.name }}</a>
          </div>
          <div class="meta-date">
            <span>{{getDate( datas.created_At)}}</span>
          </div>
        </div>
        <h4 class="title">
          <a href="#">{{ datas.title }}</a>
        </h4>
      </div>
    </div>
    </template>
  </router-link>
  <router-link
    :to="{ name: 'post', params: { postSlug: datas.slug } }" 
    v-else
    class="single__post d-lg-flex text-center text-lg-left"
  >
    <template v-if="datas">
    </template>
  </router-link>
</template>

<script>
export default {
  props: {
    category: {
      type: Boolean,
    },
    trending: {
      type: Boolean,
    },
    sports: { type: Boolean, default: false },
    datas: {
      type: Object,
    },
    counting: {
      type: Boolean,
    },
    countingtwo: {
      type: Boolean,
    },
    count: {
      type: Number,
    },
  },
  watch: {
    datas: {
      handler() {
        this.updateContent();
      },
      immediate: true,
    },
  },
  methods: {
    updateContent() {
      // Add any additional logic required to handle the update
    },
    getImageUrl(url) {
      return "https://7br-dash.z-demos.com/" + url;
    },
    getDate(dateString){
      const changedDate = dateString.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
      var date = new Date(changedDate);
      return date.toLocaleDateString('ar-EG', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    })

    }
  },
};
</script>

<style scoped>
.gallery-container{
  width:100%;
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}
.gallery_item{
padding:20px;
border-bottom: 2px solid #333;
}

.gallery_item_thumb img{
   height: 170px;
 /* width: 250px ; 
  max-width: 250px;*/
}
.gallery_item_content{
  /* width: calc(100% - 260px); */
  text-align: right;
}
.meta-categories a,.meta-date span{
font-size: 10px !important;
padding:10px 0 !important;
}
.title a{
  /* font-size: 10px !important; */
  padding:10px 0 !important;
}
</style>

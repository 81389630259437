








<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <Header @toggleSidebar="toggleSidebar" />


    <section class="post-layout-1-area pb-80">
      <div class="container container-g">
        <div class="row ">

          <div class="col-lg-8">
            <div class="post-layout-top-content">
              <div
                class="
                  post-categories
                  d-flex
                  justify-content-between
                  align-content-center
                "
              >
                <div class="categories-item">
                  <span v-if="post">  {{post.category.name}}</span>
                </div>
                <div class="categories-share">
                  <ul>
                    <li><i class="fas fa-comment"></i>45020</li>
                    <li><i class="fas fa-share-alt"></i>45020</li>
                  </ul>
                </div>
              </div>
              <div class="post-content">
                <h3 class="title">
                  {{post.title}}
                </h3>
                <div class="thumb">
                  <img v-if="post.imagePath" :src="getImageUrl(post.imagePath)" alt=""/>
                  <img v-else src="@/assets/images/7br-placeholder.png" alt="" />
                </div>
              </div>
              <div class="post-author">
                <div class="author-info">
                  <!-- <div class="thumb">
                    <img src="@/assets/images/author1.png" alt="" />
                  </div> -->
                  <h5 class="title" v-if="post.writtenBy"> كتابة : {{ post.writtenBy }}</h5>
                  <h5 class="title" v-if="post.designBy"> تصميم : {{ post.designBy }}</h5>
                  <h5 class="title" v-if="post.proofreadBy"> تدقيق : {{ post.proofreadBy }}</h5>
                  <ul>
                    <li v-if="post">{{getDate(post.created_At)}}</li>
                  </ul>
                </div>
                <div class="author-social">
                  <ul>
                    <li>
                      <a href="#"><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fab fa-twitter"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fab fa-youtube"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fab fa-instagram"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fal fa-heart"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fal fa-bookmark"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fas fa-ellipsis-v"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
           <p v-if="post" v-html="withBrTags(post.content)">

           </p>
              <div class="post-tags">
                <!-- <ul>
                  <li>
                    <a href="#"><i class="fas fa-tag"></i> Tags</a>
                  </li>
                  <li><a href="#">Health</a></li>
                  <li><a href="#">World</a></li>
                  <li><a href="#">Corona</a></li>
                </ul> -->
              </div>
              <!-- <div class="post-reader-text pt-50">
                <div class="row">
                  <div class="col-md-6">
                    <div class="post-reader-prev">
                      <span>PREVIOUS NEWS</span>
                      <h4 class="title">
                        <a href="#"
                          >Kushner puts himself in middle of white house’s
                          chaotic coronavirus response.</a
                        >
                      </h4>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="post-reader-prev">
                      <span>NEXT NEWS</span>
                      <h4 class="title">
                        <a href="#"
                          >C.I.A. Hunts for authentic virus totals in china,
                          dismissing government tallies</a
                        >
                      </h4>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-lg-4">
            <style-one :datas="Posts" />
          </div>
        </div>
      </div>
    </section>
    <!-- Google Adsense -->
    <ins class="adsbygoogle"
     style="display:block; text-align:center;"
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-4178459435047159"
     data-ad-slot="9467805258"></ins>
     <!-- End Google Adsense -->
    <div class="post-form-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="section-title">
              <h3 class="title">اترك تعليق</h3>
            </div>
            <div class="post-form-box">
              <form action="#">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-box">
                      <input type="text" placeholder="الاسم الكامل" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-box">
                      <input type="text" placeholder="البريد الالكتروني" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-box">
                      <textarea
                        name="#"
                        id="#"
                        cols="30"
                        rows="10"
                        placeholder="أخبرنا عن رأيك"
                      ></textarea>
                      <button class="main-btn" type="button">
                        نشر
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- <section class="post-comments-area pb-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="section-title">
              <h3 class="title">تعليقات</h3>
            </div>
            <div class="post-comments-list">
              <div class="post-comments-item">
                <div class="thumb">
                  <img src="@/assets/images/comments-1.png" alt="comments" />
                </div>
                <div class="post">
                  <a href="#">Reply</a>
                  <h5 class="title">Rafiqul islam</h5>
                  <p>
                    We’ve invested every aspect of how we serve our users over
                    the past Pellentesque rutrum ante in nulla suscipit, vel
                    posuere leo tristique.
                  </p>
                </div>
              </div>
              <div class="post-comments-item">
                <div class="thumb">
                  <img src="@/assets/images/comments-2.png" alt="comments" />
                </div>
                <div class="post">
                  <a href="#">Reply</a>
                  <h5 class="title">Rafiqul islam</h5>
                  <p>
                    We’ve invested every aspect of how we serve our users over
                    the past Pellentesque rutrum ante in nulla suscipit, vel
                    posuere leo tristique.
                  </p>
                </div>
              </div>
              <div class="post-comments-item ml-30">
                <div class="thumb">
                  <img src="@/assets/images/comments-3.png" alt="comments" />
                </div>
                <div class="post">
                  <a href="#">Reply</a>
                  <h5 class="title">Rafiqul islam</h5>
                  <p>
                    We’ve invested every aspect of how we serve our users over
                    the past Pellentesque rutrum ante in nulla suscipit, vel
                    posuere leo tristique.
                  </p>
                </div>
              </div>
              <div class="post-comments-item">
                <div class="thumb">
                  <img src="@/assets/images/comments-4.png" alt="comments" />
                </div>
                <div class="post">
                  <a href="#">Reply</a>
                  <h5 class="title">Rafiqul islam</h5>
                  <p>
                    We’ve invested every aspect of how we serve our users over
                    the past Pellentesque rutrum ante in nulla suscipit, vel
                    posuere leo tristique.
                  </p>
                </div>
              </div>
            </div>
            <div class="post-load-btn">
              <a class="main-btn" href="#">LOAD MORE</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->


    <div class="add-area text-center">
      <a href="#">
        <img src="@/assets/images/7br-app.jpg" alt="" />
      </a>
    </div>


    <footer-one />
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../Utility/Header/index";
import StyleOne from "../../Utility/Sidebar/StyleOne.vue";
import Posts from "../../Data/NewsRowCard";
import FooterOne from "../../Utility/Footer/FooterOne.vue";
import Drawer from "../../Mobile/Drawer.vue";
export default {
   components: { Header, StyleOne, FooterOne, 
     Drawer },
     props: ['postSlug'],
  data: () => ({
    Posts: Posts.data,
    sidebar: false,
  }),
  async created() {
    document.addEventListener("scroll", this.topToBottom);
    await this.$store.dispatch("fetchPostBySlug",{postSlug:this.postSlug});
    await this.$store.dispatch("increaseViews",{postSlug:this.postSlug});
  },
  async mounted(){
    await this.$store.dispatch("fetchAllPosts",{count:20});
    await this.$store.dispatch("fetchFeaturedPosts",{count:0});
    await this.$store.dispatch("fetchTopViewsPosts",{count:3});
  },
  computed :{
  post: function () {
            return this.$store.state.currentPost
        },
},

  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    withBrTags(content) {
      const newContent = content.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>');
      return newContent;
    },
    getImageUrl(url) {
      return "https://7br-dash.z-demos.com/" + url;
    },
    getDate(dateString){
      const changedDate = dateString.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
      var date = new Date(changedDate);
      return date.toLocaleDateString('ar-EG', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    })

    }
  },
};
</script>

<style scoped>
.container-g{
  text-align: right;
}
</style>


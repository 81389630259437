import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  videoPopup: false,
  categories: [],
  posts: [],
  page_posts : [],
  all_page_posts : [],
  top_views_posts : [],
  all_posts : [
],
  sidebar_posts : [],
  featured_posts : [],
  currentCategory: '',
  currentPost: ''
};

const mutations = {
  SET_VALUE_VIDEO: (state, payload) => {
    state.videoPopup = payload;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  fetchFirst3Posts(state, posts) {
    state.posts = posts;
  },
  fetchCategory(state, category) {
    state.currentCategory = category;
  },
  fetchPost(state, post) {
    state.currentPost = post;
  },
  fetchPagePosts(state, posts) {
    state.page_posts = posts;
  },
  fetchAllPostsPaginated(state, posts) {
    state.all_page_posts = posts;
  },
  fetchAllPosts(state, posts) {
    state.all_posts = posts;
  },
  fetchSidebarPosts(state, posts) {
    state.sidebar_posts = posts;
  },
  fetchFeaturedPosts(state, posts) {
    state.featured_posts = posts;
  },
  fetchTopViewsPosts(state, posts) {
    state.top_views_posts = posts;
  },
  SET_CURRENT_POST(state, post) {
    state.currentPost = post;
  }
};

const actions = {
  toggleVideo({ state, commit }) {
    commit('SET_VALUE_VIDEO', !state.videoPopup);
  },
  async fetchCategories({ commit }) {
    const Url = "https://7br-dash.z-demos.com/api/Categories/GetAllCategories";
    const response = await fetch(Url);
    const data = await response.json();
    commit('setCategories', data);
  },
  async fetchFirst3Posts({ commit }, { categorySlug }) {
    const Url = `https://7br-dash.z-demos.com/api/Post/GetPostsByPage?categorySlug=${categorySlug}&page=1&pageSize=3`;
    const response = await fetch(Url);
    const data = await response.json();
    commit('fetchFirst3Posts', data);
  },
  async fetchPagePosts({ commit }, { categorySlug ,page }) {
    const Url = `https://7br-dash.z-demos.com/api/Post/GetPostsByPage?categorySlug=${categorySlug}&page=${page}&pageSize=9`;
    const response = await fetch(Url);
    const data = await response.json();
    commit('fetchPagePosts', data);
  },
  async fetchAllPostsPaginated({ commit }, { page }) {
    const Url = `https://7br-dash.z-demos.com/api/Post/GetPostsByPage?page=${page}&pageSize=10`;
    const response = await fetch(Url);
    const data = await response.json();
    commit('fetchAllPostsPaginated', data);
  },
  async fetchAllPosts({ commit }, { count }) {
    const Url = `https://7br-dash.z-demos.com/api/Post/GetAllPosts?count=${count}`;
    const response = await fetch(Url);
    const data = await response.json();
    commit('fetchAllPosts', data);
  },
  async fetchSidebarPosts({ commit }, { count }) {
    const Url = `https://7br-dash.z-demos.com/api/Post/GetAllPosts?count=${count}`;
    const response = await fetch(Url);
    const data = await response.json();
    commit('fetchSidebarPosts', data);
  },
  async fetchFeaturedPosts({ commit }, { count }) {
    const Url = `https://7br-dash.z-demos.com/api/Post/GetFeaturedPosts?count=${count}`;
    const response = await fetch(Url);
    const data = await response.json();
    commit('fetchFeaturedPosts', data);
  },
  async fetchTopViewsPosts({ commit }, { count }) {
    const Url = `https://7br-dash.z-demos.com/api/Post/GetTopViews?count=${count}`;
    const response = await fetch(Url);
    const data = await response.json();
    commit('fetchTopViewsPosts', data);
  },
  async fetchCategory({ commit }, { slug }) {
    const Url = `https://7br-dash.z-demos.com/api/Categories/GetCategory?slug=${slug}`;
    const response = await fetch(Url);
    const data = await response.json();
    commit('fetchCategory', data);
  },
  async fetchPostBySlug({ commit }, { postSlug }) {
    try {
      const response = await fetch(`https://7br-dash.z-demos.com/api/Post/GetPostBySlug?slug=${postSlug}`);
      const data = await response.json();
      commit('fetchPost', data);
    } catch (error) {
      console.error('Error fetching post:', error);
      throw error;
    }
  },
  async increaseViews({ commit }, { postSlug }) {
    try {
      const response = await fetch(`https://7br-dash.z-demos.com/api/Post/IncreaseViews?slug=${postSlug}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        }
      );
      const data = await response.json();
      commit('fetchTopViewsPosts', data);
    } catch (error) {
      console.error('Error fetching post:', error);
      throw error;
    }
  }
};

export default new Vuex.Store({
  state,
  mutations,
  actions
});

<template>
  <div
    :class="[
      'newspark-header-main-menu',
      darkClass && 'menuItem' in darkClass ? darkClass.menuItem : '',
    ]"
  >
    <ul v-if="nav_items">
      <li v-for="(item, index) in nav_items" :key="index">

        <router-link  :to="`${item.link}`">{{
          item.linkText
        }}</router-link>

      </li>
    </ul>
  </div>
</template>

<script>
import navItems from "../../Data/NavItems.json";
export default {
  props: {
    darkClass: {
      type: Object,
    },
  },
  data: () => ({
    nav_items: navItems.data, 
  }),
};
</script>

<style  scoped>
.newspark-header-main-menu.header-two ul > li > a {
  color: #ffffff;
}
.newspark-header-main-menu.header-three-nav ul > li > a {
  color: white;
}
.header-three-nav {
  margin-left: 35px;
}
ul{
  width:90vw;
  padding: 20px 10vw  !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
 a{
  font-size:20px !important;
  font-weight: 800 !important;
  border-bottom: 2px solid rgba(0,0,0,0);
}
a:hover{
  border-bottom: 2px solid #000;
}
</style>

<template>
  <div id="app">
    <router-view />  
  </div>
</template>

<script>
import LanguageSwitcher from './locales/LanguageSwitcher.vue';


export default {
  


vue  : {
    LanguageSwitcher
  },

  name: 'App',

  methods: {
    changeLanguage() {
      const newLocale = this.$i18n.locale === 'ar' ? 'en' : 'ar';
      this.$i18n.locale = newLocale;
    }
    
  }
  
};

</script>

<style>
/* بعض التنسيقات الأساسية */
nav ul {
  list-style-type: none;
  padding: 0;
}
nav li {
  display: inline;
  margin-right: 10px;
}
</style>

<template>
  <section class="about-item-area">
    <div class="container">
           <!-- التصنيفات -->
    <div class="categories-section col my-5" >
      <div class="category-list">
        <div :class="['category-item', category === 'all' ? 'active' : '']" @click="selectCategory('all')">الكل</div>
        <div  v-for="cat in $store.state.categories" :key="cat.id"
          :class="['category-item', category === cat.slug  ? 'active' : '']"
         @click="selectCategory(cat.slug)">
          {{ cat.name }}
      </div>
    </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="about-tab-btn mt-40">
              
              <div class="about-post-items">
                  <div class="row">
                      <div v-for="(data,index) in posts" :key="index" class="col-lg-3 col-md-3">
                          <div class="trending-news-item mb-30">
                              <div class="trending-news-thumb">
                                <img v-if="data.imagePath" :src="getImageUrl(data.imagePath)" alt=""/>
                                <img v-else src="@/assets/images/7br-placeholder.png" alt="" />
                              </div>
                              <div class="trending-news-content">
                                  <div class="post-meta">
                                      <div class="meta-categories">
                                          <a href="#">{{data.category.name }}</a>
                                      </div>
                                      <div class="meta-date">
                                          <span>{{ getDate(data.created_At) }}</span>
                                      </div>
                                  </div>
                                  <h3 class="title"><a href="#">{{data.title}}</a></h3>
                                  <div class="mt-5">
                                    <router-link :to="{ name: 'post', params: { postSlug: data.slug } }" >
                                    <button class="more-link">
                                      متابعة القراءة
                                    </button>
                                  </router-link>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-if="posts.length === 0" :key="index" class="col-lg-3 col-md-3">
                          <div class="trending-news-item mb-30">
                           لا يوجد مقالات في هذا القسم
                          </div>
                      </div>
                      <div class="col-lg-12"  v-if="posts.length !== 0" >
                          <div class="pagination-item">
                              <nav aria-label="Page navigation example">
                                <ul class="pagination">
                            <li  v-bind:class = "(page != 1 )?'page-item active':'page-item'">
                              <button :disabled="page == 1"  class="page-link" @click="goToPage(1)">1</button>
                            </li>
                            <li v-bind:class = "(hasPreviousPage)?'page-item active':'page-item'">
                              <button :disabled="!hasPreviousPage" class="page-link" @click="previousPage()">السابق</button>
                            </li>
                            <li class="page-item" v-bind:class = "(hasNextPage)?'active':'page-item'">
                              <button :disabled="!hasNextPage" class="page-link" @click="nextPage()">التالي</button>
                            </li>
                            <li  v-bind:class = "(page != totalPages )?'page-item active':'page-item'">
                              <button :disabled="page == totalPages"  class="page-link" @click="goToPage(totalPages)">الأخيرة</button>
                            </li>
                          </ul>
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import aboutDatas from "../Data/About";
export default {
  components: { },
  data: () => ({
    selected: "latest",
    aboutDatas: aboutDatas.data,
    page : 1,
    category : 'all'
  }),
  computed :{
  posts: function () {
            return this.$store.state.page_posts.data
        },
    hasNextPage : function () {
            return this.$store.state.page_posts.hasNextPage
        },
        hasPreviousPage : function () {
            return this.$store.state.page_posts.hasPreviousPage
        },
        totalPages : function () {
            return this.$store.state.page_posts.totalPages
        }
},
  async created(){
    await this.$store.dispatch("fetchPagePosts",{categorySlug:this.category,page:this.page});
  },
  async mounted(){
    await this.$store.dispatch("fetchCategories");
  },
  methods:{
    selectTab(value) {
      this.selected = value;
    },
    async selectCategory(category){
      this.category = category;
      await this.$store.dispatch("fetchPagePosts",{categorySlug:this.category,page:this.page});
    },
    async nextPage(){
      this.page= this.page +1;
      await this.$store.dispatch("fetchPagePosts",{categorySlug:this.category,page: this.page});
    },
    async previousPage(){
      this.page= this.page -1;
      await this.$store.dispatch("fetchPagePosts",{categorySlug:this.category,page: this.page});
    },
    async goToPage(p){
      this.page= p;
      await this.$store.dispatch("fetchPagePosts",{categorySlug:this.category,page: p});
    },
    withBrTags(content) {
      const index = content.indexOf("\\n");
      const newContent = content.substring(0, index).replace(/(?:\\r\\n|\\r|\\n)/g, '<br>');
      return newContent;
    },
    getImageUrl(url) {
      return "https://7br-dash.z-demos.com/" + url;
    },
    getDate(dateString){
      const changedDate = dateString.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
      var date = new Date(changedDate);
      return date.toLocaleDateString('ar-EG', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    })

    }
  }
};
</script>

<style scoped>
.more-link{
  background-color: #6c3ea5;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}
.category-list{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.category-item{
  padding:8px;
  border-radius: 8px;
  border:1px solid black;
  margin:8px;
}
.category-item{
  cursor: pointer;
  color:black;
}
.category-item.active{
  background: #ECB33B;
  border-color: #ECB33B;
}
</style>

<template>
  <div class="about-author-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-author-content">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/">
                  Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
               about
                </li>
              </ol>
            </nav>
            <div class="author-content">
              <h3 class="title">  منصة حبر</h3>
              <div class="text">
                <p>
                  يهدف خبر أون لاين لأن يجمع عبر صفحاته كلّ ما يهم القارئ العربي وفي كلّ المجالات، ليبقى على اطلاع واسع على أهم المتجرات والأفكار الأدبية والفنية والعلميّة، منجمع في قضاء المدونة شمل محتوى القراءة مع محتوى الكتابة، كما يمكنا، أن يكون جزءا منها بمساهمته ومشاركته في تكوين المدونة وإغناء محتواها
                </p>
              </div>
              <div class="logo-mid">
                <img :src="require(`@/assets/images/logo.png`)" alt="feature">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.author-content .thumb {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
  height: 115px;
  width: 115px;
  text-align: center;
  line-height: 112px;
  border: 2px solid #e4e5e6;
}
.logo-mid{
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
